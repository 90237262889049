import React, { useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";

function About() {
  const [isHovered, setHovered] = useState(false);

  return (
    <section id="about" className="texture">
      <Container className="py-5">
        <Card data-aos="flip-left" className="p-4">
          <Card.Title>
            <h1 className="judul_hitam mb-0">About Me</h1>
          </Card.Title>
          <Card.Body>
            <Row>
              <Col xs={12} md={5}>
                <div>
                  <Image
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    className={
                      isHovered
                        ? "img_profile_normal mx-auto d-block"
                        : "img_profile mx-auto d-block"
                    }
                    src="./images/foto_profil.jpg"
                    alt="profil"
                    width="200"
                    fluid
                  />
                </div>
              </Col>
              <Col xs={12} md={7}>
                <p className="about_desc pt-3">
                  Hello, my name is Arkin and I am a highly motivated and
                  dedicated Software QA Engineer at{" "}
                  <a
                    href="https://sealabsindonesia.sea.com/about"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Sea Labs Indonesia
                  </a>
                  . I am a graduate of{" "}
                  <a
                    href="https://www.uajy.ac.id/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Atma Jaya Yogyakarta University
                  </a>
                  , where I not only excelled academically but also took on
                  several freelance projects. Through these experiences, I have
                  gained a strong foundation in software development and quality
                  assurance, and I am always eager to learn and grow in my role.
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
}

export default About;
