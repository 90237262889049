const listProjects = [
    {
        id: 1,
        title: "tindog",
        desc: "A Tinder clone website, but for dogs!",
        languages: "HTML, CSS",
        link: "https://arkinjulijanto.github.io/tindog/index.html",
        image: "./images/tindog.jpg"
    },
    {
        id: 2,
        title: "Drum Kit",
        desc: "A simple online drum kit that you can play.",
        languages: "HTML, CSS, Javascript",
        link: "https://arkinjulijanto.github.io/drumKit/index.html",
        image: "./images/drumkit.jpg"
    },
    {
        id: 3,
        title: "Simon Game",
        desc: "A fun memory game website for anyone to play!",
        languages: "HTML, CSS, Javascript",
        link: "https://arkinjulijanto.github.io/SimonGame/index.html",
        image: "./images/simongame.jpg"
    },
    {
        id: 4,
        title: "Keeper",
        desc: "A simple Google Keep clone website using React JS.",
        languages: "HTML, CSS, Javascript",
        link: "https://arkinjulijanto.github.io/Keeper-App/",
        image: "./images/keeper.jpg"
    },
    {
        id: 5,
        title: "Regist",
        desc: "A website of church service's registration needs for Gereja Bethany Indonesia Salatiga.",
        languages: "HTML, CSS, Javascript, PHP",
        link: "https://regist.arkin-dev.com/",
        image: "./images/ibadah.jpg"
    },
    {
        id: 6,
        title: "AKB",
        desc: "A website for korean barbeque restaurant to help and simplify the business processes. Built with Nuxt JS and Laravel.",
        languages: "HTML, CSS, Javascript, PHP",
        link: "https://akb.arkin-dev.com/",
        image: "./images/akb.png"
    },
    {
        id: 7,
        title: "IKDKI",
        desc: "A website for organize and manage member of Ikatan Dosen Katolik Indonesia. Built with React JS and Laravel.",
        languages: "HTML, CSS, Javascript, PHP",
        link: "https://ikdki.arkin-dev.com/",
        image: "./images/ikdki.png"
    },
    {
        id: 8,
        title: "Sistem Rapat",
        desc: "A website for organize and manage meetings for a department. Built with React JS and Laravel.",
        languages: "HTML, CSS, Javascript, PHP",
        link: "https://sira.arkin-dev.com/",
        image: "./images/sisra.png"
    },
    {
        id: 9,
        title: "Borobudur DRM Communication Platform",
        desc: "A website for manage content and communication platform for Borobudur Disaster Risk Management. Built with Vue JS and Laravel.",
        languages: "HTML, CSS, Javascript, PHP",
        link: "https://drm.arkin-dev.com/",
        image: "./images/drm.png"
    },
];

export default listProjects;