import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";

function projectKanan(props) {
    return (
        <div className="text-right mx-3 pt-2">
            <Row>
                <Col xs={{span:12, order:2}} md={{span:7, order:1}}>
                    <h2>{props.title}</h2>
                    <p className="project_desc mb-1">{props.desc}</p>
                    <p className="project_lang mb-3">Languages used : <strong>{props.lang}</strong></p>
                    <Button 
                        className="projectBtn" 
                        variant="outline-danger" 
                        href={props.link} 
                        target="_blank" 
                    >
                        See Preview
                    </Button>
                </Col>
                <Col xs={{span:12, order:1}} md={{span:5, order:2}}>
                    <Image src={props.img} alt="project" fluid width="400"/>
                </Col>
            </Row>
        </div>
    );
}

export default projectKanan;