import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Typing from "./Typing";
import { Link } from "react-scroll";

function Home() {
  const typeConfig = ["A Web Developer", 3000, "A Software QA Engineer", 3000];
  return (
    <section id="home" className="home_padding">
      <Container>
        <Row className="home_padding">
          <Col
            data-aos="fade-up"
            xs={{ span: 12, order: 2 }}
            md={{ span: 5, order: 1 }}
          >
            <div className="mt-5 pt-3">
              <h1 className="home_name">I'm Arkin</h1>
            </div>
            <p className="home_sub mt-3">
              <Typing className="home_sub" content={typeConfig} type="home" />
            </p>
            <Link to="about" smooth={true} duration={1000} offset={-50}>
              <Button
                className="myBtn mt-4 mb-3"
                variant="outline-danger"
                size="lg"
              >
                About Me
              </Button>
            </Link>
          </Col>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }}>
            <Image
              data-aos="fade-left"
              className="img_home"
              src="./images/home.svg"
              alt="home"
              width="600"
              fluid
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Home;
