import React from "react";
import { Card, Container } from "react-bootstrap";
import Typing from "./Typing";
import listProjects from "../listProjects";
import ProjectKiri from "./projectComponents/projectKiri"
import ProjectKanan from "./projectComponents/projectKanan"

function Projects() {
    function createProject(project) {
        if (project.id !== 1) {
            if (project.id % 2 !== 0) {
                return (
                    <div key={project.id} data-aos="fade-up" data-aos-delay="600">
                        <hr className="my-4" />
                        <ProjectKiri 
                            title={project.title}
                            desc={project.desc}
                            lang={project.languages}
                            link={project.link}
                            img={project.image}
                        />
                    </div>
                );
            } else {
                return (
                    <div key={project.id} data-aos="fade-up" data-aos-delay="600">
                        <hr className="my-4" />
                        <ProjectKanan 
                            title={project.title}
                            desc={project.desc}
                            lang={project.languages}
                            link={project.link}
                            img={project.image}
                        />
                    </div>
                );
            }
        } else if (project.id === 1) {
            return (
                <div key={project.id} data-aos="fade-up" data-aos-delay="600">
                    <ProjectKiri 
                        title={project.title}
                        desc={project.desc}
                        lang={project.languages}
                        link={project.link}
                        img={project.image}
                    />
                </div>
            );
        }
    }

    return (
        <section id="projects" className="texture">
            <Container className="py-5">
                <Card className="p-4">
                    <Card.Title data-aos="fade-up">
                        <h1 className="judul_hitam">Projects</h1>
                        <p className="desc_hitam mb-4">
                            <Typing content="Here are some of my projects" />
                        </p>
                    </Card.Title>
                    <Card.Body>
                        <Container>
                            {listProjects.map(createProject)}
                        </Container>    
                    </Card.Body>
                </Card>
            </Container>
        </section>
    );
}

export default Projects;