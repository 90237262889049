import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";

function projectKiri(props) {
    return (
        <div className="mx-3">
            <Row>
                <Col xs={12} md={5}>
                    <Image src={props.img} alt="project" fluid width="400"/>
                </Col>
                <Col xs={12} md={7}>
                    <h2>{props.title}</h2>
                    <p className="project_desc mb-1">{props.desc}</p>
                    <p className="project_lang mb-3">Languages used : <strong>{props.lang}</strong></p>
                    <Button 
                        className="projectBtn" 
                        variant="outline-danger" 
                        href={props.link} 
                        target="_blank" 
                    >
                        See Preview
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default projectKiri;