import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

function Contact() {
    return (
        <section id="contact" className="py-3">
            <Container>
                <h1 className="judul_putih mt-2" data-aos="fade-up">Contact Me</h1>
                <Row>
                    <Col className="p-2 text-center">
                        <div className="round py-2 m-3" data-aos="fade-up" data-aos-delay="600" >
                            <a href="https://www.linkedin.com/in/arkinjulijanto" target="_blank" rel="noreferrer">
                                <LinkedInIcon />
                            </a>
                        </div>
                        <div className="round py-2 m-3" data-aos="fade-up" data-aos-delay="1000">
                            <a href="https://github.com/arkinjulijanto" target="_blank" rel="noreferrer">
                                <GitHubIcon />
                            </a>
                        </div> 
                        <div className="round py-2 m-3" data-aos="fade-up" data-aos-delay="1400">
                            <a href="https://www.instagram.com/arkinjulianto" target="_blank" rel="noreferrer">
                                <InstagramIcon />
                            </a>
                        </div>
                        <div className="round py-2 m-3" data-aos="fade-up" data-aos-delay="1800">
                            <a href="mailto:arkinjulianto@gmail.com" target="_blank" rel="noreferrer">
                                <MailOutlineIcon />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Contact;