import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Typing from "./Typing";
import CodeIcon from "@material-ui/icons/Code";
import ComputerIcon from "@material-ui/icons/Computer";
import QAIcon from "@material-ui/icons/VerifiedUser";

function Skills() {
  return (
    <section id="skills" className="py-5">
      <Container>
        <div data-aos="fade-up">
          <h1 className="judul_putih">My Skills</h1>
          <p className="desc_putih mb-4">
            <Typing content="Here are some of my skills that i have learn until now" />
          </p>
        </div>
        <Row>
          <Col xs={12} md={6}>
            <Card
              data-aos="slide-up"
              data-aos-delay="600"
              className="p-3 card_skills"
            >
              <Card.Title className="no_margin_bottom">
                <CodeIcon className="icon_skills mx-auto" />
                <h3 className="title_skills">Front-end Developer</h3>
              </Card.Title>
              <Card.Body className="p-2">
                <h5 className="sub_skills">Languages I write :</h5>
                <p className="sub_content_skills">
                  HTML, CSS, JavaScript, Typescript
                </p>
                <h5 className="sub_skills">Frameworks & Tools :</h5>
                <p className="sub_content_skills">Redis</p>
                <p className="sub_content_skills">Jest</p>
                <p className="sub_content_skills">Axios</p>
                <p className="sub_content_skills">
                  Bootstrap, Tailwind, Chakra UI
                </p>
                <p className="sub_content_skills">ESLint</p>
                <p className="sub_content_skills">React JS</p>
                <p className="sub_content_skills">Vue JS</p>
                <p className="sub_content_skills">Nuxt JS</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="ml-auto mt-4">
            <Card
              data-aos="slide-up"
              data-aos-delay="600"
              className="p-3 card_skills"
            >
              <Card.Title className="no_margin_bottom">
                <ComputerIcon className="icon_skills mx-auto" />
                <h3 className="title_skills">Back-end Developer</h3>
              </Card.Title>
              <Card.Body className="p-2">
                <h5 className="sub_skills">Languages I code :</h5>
                <p className="sub_content_skills">PHP, JavaScript, GO</p>
                <h5 className="sub_skills">Databases :</h5>
                <p className="sub_content_skills">MySQL, MongoDB, PostgreSQL</p>
                <h5 className="sub_skills">Frameworks & Tools :</h5>
                <p className="sub_content_skills">Express</p>
                <p className="sub_content_skills">GIN</p>
                <p className="sub_content_skills">GORM</p>
                <p className="sub_content_skills">Laravel</p>
                <p className="sub_content_skills">Git, Github, Gitlab</p>
                <p className="sub_content_skills">Terminal</p>
                <p className="sub_content_skills">Visual Studio Code</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="mt-4">
            <Card
              data-aos="slide-up"
              data-aos-delay="600"
              className="p-3 card_skills"
            >
              <Card.Title className="no_margin_bottom">
                <QAIcon className="icon_skills mx-auto" />
                <h3 className="title_skills">Software QA Engineer</h3>
              </Card.Title>
              <Card.Body className="p-2">
                <h5 className="sub_skills">Languages I write (automation) :</h5>
                <p className="sub_content_skills">Python, GO, Javascript</p>
                <h5 className="sub_skills">Frameworks & Tools :</h5>
                <p className="sub_content_skills">Playwright</p>
                <p className="sub_content_skills">Katalon</p>
                <p className="sub_content_skills">pytest</p>
                <p className="sub_content_skills">Allure Report</p>
                <p className="sub_content_skills">k6</p>
                <p className="sub_content_skills">Postman</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Skills;
