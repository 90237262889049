import React from "react";
import { Container, Image } from "react-bootstrap";

function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className="p-2">
            <Container>
                    <p>Copyright ⓒ {year}</p>
                    <Image src="./images/logo-horizontal.png" fluid width="70" alt="logo" />
            </Container>
        </footer>
    );
}

export default Footer;