import React from "react";
import Typical from "react-typical";

function Typing(props) {
  return props.type === "home" ? (
    <Typical loop={Infinity} steps={props.content} wrapper="b" />
  ) : (
    <Typical loop={1} steps={["", 1500, props.content, 5000]} wrapper="b" />
  );
}

export default Typing;
