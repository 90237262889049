import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";

function NavigationBar() {
  return (
    <Navbar variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand
          onClick={() => scroll.scrollToTop({ smooth: true, duration: 1500 })}
        >
          <img
            src="./images/logo-head.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link
              className="nav-link"
              to="about"
              smooth={true}
              duration={1000}
              offset={-50}
            >
              About
            </Link>
            <Link
              className="nav-link"
              to="skills"
              smooth={true}
              duration={1000}
              offset={-50}
            >
              Skills
            </Link>
            <Link
              className="nav-link"
              to="projects"
              smooth={true}
              duration={1000}
              offset={-50}
            >
              Projects
            </Link>
            <Link
              className="nav-link"
              to="contact"
              smooth={true}
              duration={1000}
              offset={-50}
            >
              Contact
            </Link>
            <Nav.Link
              className="nav-link"
              href="https://blog.arkin-dev.com/"
              target="_blank"
              smooth={true}
              duration={1000}
              offset={-50}
            >
              Blog
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
